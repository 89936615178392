.label {
  font-family: Poppins-SemiBold, sans-serif;
  font-weight: 600;
}

.validationError {
  font-size: 12px;
  line-height: 20px;
  color: var(--Error-600);
}

.radioGroupWrapper > *:not(:last-child) {
  margin-bottom: 8px;
}

.absenceIcon {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 8px;
}

.upcomingIcon {
  background-color: transparent;
}

.ongoingIcon {
  border-color: #fcc067;
  background-color: #fcc067;
}

@media screen and (min-width: 576px) {
  .absenceIcon {
    margin-right: 0;
  }
}
