.container {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  cursor: pointer;
  background: none;
  border: none;
}

.title {
  font-family: Poppins-SemiBold, sans-serif;
  color: var(--Primary-700);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin-right: 10px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hidden {
  display: none;
}

@keyframes slideIn {
  0% {
    flex: 0;
  }

  100% {
    flex: 1;
  }
}

@keyframes slideOut {
  0% {
    flex: 1;
  }

  100% {
    flex: 0;
  }
}
