:global(.popupChildrenTree-overlay) {
  background: transparent !important;
}

:global(.popupChildrenTree-content) {
  position: fixed !important;
  left: -1000px;
  top: -1000px;
  box-shadow:
    0 5px 10px rgba(0, 0, 0, 0.07),
    0 3px 6px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 0;
  border: 1px solid var(--Grey-200);
  width: 640px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--Grey-800);
  max-height: 260px;
  overflow: scroll;
}

:global(.popupChildrenTree-arrow) {
  display: none;
}

.popupBody {
  padding: 12px 16px;
  width: max-content;
}

.popupMainChild {
  font-weight: 600;
  font-size: 14px;
  color: var(--Grey-600);
}

.popupMainChild:hover,
.popupRoot:hover,
.popupRootChild:hover,
.popupSecondaryChild:hover {
  color: var(--Primary-700);
  text-decoration: underline;
}

.popupSecondaryChild {
  color: var(--Grey-600);
}

.popupSecondaryChild::before {
  content: "";
  width: 12px;
  height: 18px;
  border: 1px solid var(--Grey-500);
  border-width: 0 0 1px 1px;
  border-radius: 0 0 0 8px;
  position: relative;
  left: -7px;
  top: -3px;
  display: inline-block;
}

.popupRoot {
  color: var(--Grey-600);
}

.popupRootChild {
  font-weight: 600;
  font-size: 14px;
  color: var(--Grey-600);
}

.popupRootChild::before {
  content: "";
  width: 12px;
  height: 18px;
  border: 1px solid var(--Grey-500);
  border-width: 0 0 1px 1px;
  border-radius: 0 0 0 8px;
  position: relative;
  left: -7px;
  top: -3px;
  display: inline-block;
}
