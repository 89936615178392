.container {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  background-color: #f9fafb;
  border-radius: 4px;
}

.fullWidth {
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  color: var(--Grey-600);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin-left: 4px;
}

.description {
  color: var(--Grey-800);
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin-top: 8px;
  line-break: anywhere;
}
