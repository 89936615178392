.additionalText {
  color: var(--Grey-500);
}

.camerasDropdown {
  display: flex;
  align-items: center;
  gap: 8px;
  width: auto;
  margin-left: auto;
}

.dropdownBtn {
  width: 256px;
}
