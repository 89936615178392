.alert * {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.alert {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px 12px;
  border: 1px solid var(--Grey-200);
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
  background: var(--White);
  color: var(--Grey-800);
}

.alertInfo {
  background: var(--Primary-50);
  border: 1px solid var(--Primary-400);
  color: var(--Primary-700);
}

.alertInfoOutline {
  background: var(--White);
  border: 1px solid var(--Primary-400);
  color: var(--Primary-700);
}

.alertWarning {
  background: var(--Warning-50);
  border: 1px solid var(--Warning-200);
  color: var(--Warning-700);
}

.alertWarningOutline {
  background: var(--White);
  border: 1px solid var(--Warning-200);
  color: var(--Warning-700);
}

.alertError {
  background: var(--Error-50);
  border: 1px solid var(--Error-300);
  color: var(--Error-600);
}

.alertErrorOutline {
  background: var(--White);
  border: 1px solid var(--Error-300);
  color: var(--Error-600);
}

.alertInfo .alertIcon path,
.alertInfoOutline .alertIcon path {
  stroke: var(--Primary-700);
}

.alertWarning .alertIcon path,
.alertWarningOutline .alertIcon path {
  stroke: var(--Warning-700);
}

.alertError .alertIcon path,
.alertErrorOutline .alertIcon path {
  stroke: var(--Error-500);
}

.alertTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.closeIcon {
  margin-left: 6px;
  cursor: pointer;
}

.alertItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0 8px 0;
}

.alertItem:not(:last-child) {
  border-bottom: 1px solid var(--Error-100);
}

.alertItemTitle {
  font-weight: 600;
}

.alertItemNote {
  font-weight: normal;
  margin-left: 6px;
}

.alertText {
  font-size: 12px;
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 16px;
  max-width: 70%;
}

.alertText.icon {
  margin-left: 38px;
}
