body {
  margin: 0;
  font-family: Poppins-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --White: #fff;
  --Black: #000;
  --Grey: #333;
  --Grey-50: #fbfcfe;
  --Grey-100: #eceef3;
  --Grey-150: #ebeef4;
  --Grey-200: #d7dbe3;
  --Grey-300: #b6bdc8;
  --Grey-400: #8f9baa;
  --Grey-500: #7a8692;
  --Grey-600: #606b77;
  --Grey-700: #48515b;
  --Grey-800: #303840;
  --Grey-900: #1a1b1d;
  --Success-50: #e8fceb;
  --Success-100: #baf2c4;
  --Success-200: #8fe09f;
  --Success-300: #6ece80;
  --Success-400: #53bd69;
  --Success-500: #2e9d46;
  --Success-600: #009c93;
  --Success-700: #00847c;
  --Success-800: #064b16;
  --Success-900: #043a0f;
  --Warning-50: #fef9ec;
  --Warning-100: #fbebbc;
  --Warning-200: #f4d985;
  --Warning-300: #ebc54c;
  --Warning-400: #dcaf1e;
  --Warning-500: #c49d12;
  --Warning-600: #9c7f11;
  --Warning-700: #7e6507;
  --Warning-800: #634f03;
  --Warning-900: #4c3e01;
  --Error-50: #ffedf0;
  --Error-100: #ffcad0;
  --Error-200: #ffa6b0;
  --Error-300: #f3727e;
  --Error-400: #e84652;
  --Error-500: #dd222d;
  --Error-600: #ba282e;
  --Error-700: #962b2f;
  --Error-800: #732b2d;
  --Error-900: #4f2526;

  color: var(--Grey-800);
}

a,
span.clickable {
  color: var(--Primary-700);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p,
ul {
  margin: 0;
}

h1 {
  font-family: Poppins-SemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
}

h2 {
  font-family: Poppins-SemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--Grey-800);
}

button {
  background: none;
  border: none;
  padding: 0;
}

p,
span.text,
button {
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

span.small {
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

label,
span.strong {
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

label.description {
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

p.subTitle {
  font-family: Poppins-Regular, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

p.subTitleSemiBold {
  font-family: Poppins-SemiBold, sans-serif;
  font-weight: 600;
  font-size: 16px;
}

span.smallStrong,
p.subTitleSmall {
  font-family: Poppins-SemiBold, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--Grey-300); /* color of the scroll thumb */
  border-radius: 6px; /* roundness of the scroll thumb */
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--Grey-500); /* color on hover */
}

.p-treeselect-items-wrapper::-webkit-scrollbar,
.popup-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.visuallyHidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

p.description {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--Grey-400);
}

:focus-visible {
  outline: none;
  box-shadow: 0 0 4px 2px var(--Primary-700);
}

@media screen and (min-width: 1024px) {
  a:hover,
  span.clickable:hover {
    cursor: pointer;
    color: var(--Primary-800);
  }
}
