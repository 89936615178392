.radioContainer {
  border: none;
  float: none;
  overflow: hidden;
}

.radio {
  float: left;
  padding: 0;
  margin: 4px 8px 4px 4px;
  border: 0.8px solid var(--Grey-200);
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
}

.radioContainer:focus-visible {
  outline: var(--Primary-700) auto 1px;
}

.radio:checked {
  background-color: var(--Primary-50);
  border-color: var(--Primary-700);
}

.radio:checked::before {
  content: "";
  display: block;
  background-color: var(--Primary-700);
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 50%;
}

.radioIcon {
  float: left;
  margin-right: 6px;
}

.radioText {
  padding: 0 4px;
  text-align: right;
  color: var(--Grey-600);
  display: contents;
}

.radioDescription {
  text-align: left;
  float: right;
  color: var(--Grey-600);
}

.radioGroupWrapper {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--Grey-200);
}

.radioGroupWrapper > :not(:last-child) {
  margin-bottom: 8px;
}

.fullWidth {
  width: 100%;
}

.disabled {
  filter: grayscale(1) opacity(0.8);
}

.noBorder {
  border: none;
}

@media screen and (min-width: 576px) {
  .radio {
    width: 16px;
    height: 16px;
  }

  .radio:checked::before {
    width: 8px;
    height: 8px;
    margin: 3.5px;
  }

  .radioText {
    display: initial;
  }
}
