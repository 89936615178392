.sideBar *,
.sideBarSecondary * {
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.sideBar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex-shrink: 0;
  width: 236px;
  height: 100%;
  padding: 20px 12px 16px 12px;
  background: var(--White);
  overflow-y: auto;
}

.sideBarMinimized {
  width: 64px;
}

.sideBarProfileContainerTop {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 35px 0;
  padding: 0 4px;
}

.sideBarProfileContainerBottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 8px 0 8px;
  cursor: pointer;
}

.sideBarProfileImage,
.sideBarProfileImagePlaceholder {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 4px;
  background: var(--Primary-600);
}

.sideBarProfileImageSecondary,
.sideBarProfileImagePlaceholderSecondary {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
}

.sideBarProfileName {
  color: var(--Grey-800);
  margin-left: 8px;
}

.sideBarProfileNameSecondary {
  color: var(--Grey-600);
  margin-left: 8px;
}

.sideBarMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.sideBarFooterContainer {
  margin-top: auto;
}

.sideBarLink,
.sideBarLinkButton {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  color: var(--Grey-600);
  padding: 8px;
  cursor: pointer;
  box-shadow: none;
  border: none;
  background: none;
  background-color: transparent;
  outline: none;
  text-decoration: none !important;
}

.sideBarLink:focus,
.sideBarLinkButton:focus {
  background: var(--Primary-50);
  color: var(--Primary-700);
  border-radius: 4px;
}

.sideBarLink:hover,
.sideBarLinkButton:hover {
  color: var(--Primary-700) !important;
  background: var(--Primary-50);
  border-radius: 4px;
}

.sideBarLink:hover svg path,
.sideBarLink:hover svg,
.sideBarLinkButton:hover svg path,
.sideBarLinkButton:hover svg,
a.sideBarLinkActive svg path,
a.sideBarLinkActive svg {
  stroke: var(--Primary-700) !important;
}

a.sideBarLinkActive {
  background: var(--Primary-50);
  color: var(--Primary-700);
  border-radius: 4px;
}

.linkWrapper {
  width: 100%;
}

.activeLinkWrapper {
  pointer-events: none;
}

.withSpace {
  padding-left: 24px;
}

.sideBarLinkBack .sideBarLinkText {
  font-size: 14px;
}

.sideBarLinkText,
.sideBarLinkButtonText {
  margin-left: 8px;
  text-align: left;
}

.truncateText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sideBarLinkIcon {
  margin: 0;
}

.sideBarLinkImportantIcon {
  margin-left: auto;
}

.sideBarHorizontalDivider {
  background: var(--Grey-100);
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  width: 100%;
  margin: 16px 0;
}

.sideBarSecondary {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 236px;
  padding: 16px 16px 0 8px;
  background: var(--White);
  overflow-y: auto;
}

.sideBarSecondaryTopContainer {
  margin-bottom: 16px;
}

/* #region PopupContextMenu */

/* #region reactjs-popup restyling */

/* stylelint-disable-next-line selector-class-pattern */
:global(.popupContextMenu-content) {
  padding: 4px 0;
  background: var(--White);
  border: 1px solid var(--Grey-200);
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(27, 32, 40, 0.12), 0 0 1px rgba(0, 0, 0, 0.04);
  color: var(--Grey-800);
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

:global(.linksWrapper-content) {
  max-height: 244px;
  overflow: auto;
}

/* stylelint-disable-next-line selector-class-pattern */
:global(.popupContextMenu-arrow) {
  display: none;
}

/* #endregion */

.popupContextMenuItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.popupContextMenuLink,
.popupContextMenuLinkButton {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: var(--Grey-800);
  text-decoration: none;
  padding: 8px 16px;
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  box-shadow: none;
  border: none;
  background: none;
}

.popupContextMenuLink:is(:hover, :active),
.popupContextMenuLinkButton:is(:hover, :active) {
  box-shadow: none;
  appearance: none;
  border: none;
  background: none;
  background-color: transparent;
  outline: none;
  color: var(--Primary-700);
}

.popupContextMenuLinkButton:hover svg path {
  stroke: var(--Primary-700);
}

.popupContextMenuLinkText,
.popupContextMenuLinkButtonText {
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.popupContextMenuLinkTextNoIcon {
  margin-left: 28px;
}

.dropdownFlag {
  width: 20px !important;
  height: 15px !important;
}

:global([role="tooltip"].popup-content.autoWidth-content) {
  width: auto;
}

/* stylelint-disable-next-line selector-class-pattern */
:global(.table-filter-content) {
  width: 276px !important;
  max-height: 450px;
  overflow-y: auto;
}

/* #endregion */
