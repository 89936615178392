.container {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid #d7dbe3;
  background: none;
  cursor: pointer;
}

.headerLeftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 70%;
}

.headerRightSide {
  display: flex;
  flex-direction: row;
  justify-self: center;
}

.iconWrapper {
  margin-right: 18px;
}

.mainGroupInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.title {
  color: var(--Grey-800);
  font-family: Poppins-SemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.1px;
}

.description {
  color: var(--Grey-600);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: left;
}

.details {
  margin-right: 20px;
  font-family: Poppins-SemiBold, sans-serif;
  color: var(--Grey-600);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
}

.body {
  background-color: var(--White);
  border: 1px solid #d7dbe3;
  border-top: none;
  max-height: 0;
  transition: max-height 0.6s ease-in-out;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
}

.bodyNoTransition {
  background-color: var(--Grey-50);
  border: 1px solid #d7dbe3;
  border-top: none;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
}

.body.tintedBody {
  background-color: var(--Grey-50);
}

.body.visibleBody {
  max-height: 10000px;
  overflow-y: scroll;
}

.innerBody {
  margin: 24px 36px;
}

.hidden {
  display: none;
}

.testItem {
  height: 40px;
  width: 80%;
  background: #303840;
  margin-bottom: 30px;
}
