.loginWrapper {
  height: 100dvh;
  overflow: auto;
}

.loginContainer {
  padding: 20px;
  max-width: 500px;
  margin: auto;
}

.formContainer {
  margin-top: 32px;
}

.brandImg {
  display: block;
  width: 100%;
  max-width: 500px;
  max-height: 211px;
  object-fit: cover;
  object-position: top;
  margin: auto;
}

.loginButtons {
  margin-top: 16px;
}

.loginBtn {
  width: 100%;
  & span {
    margin: auto;
  }
}

.forgotBtn {
  font-family: Popins-semiBold, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  display: block;
  width: max-content;
  margin-top: 16px;
}

.adBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  white-space: normal;
}

.forgotPasswordSubTitle,
.forgotPasswordTitle,
.forgotPasswordUsername {
  margin-top: 32px;
}

.forgotPasswordBtns {
  margin-top: 16px;
}

.resetPwdBtn {
  width: 100%;

  & span {
    margin: auto;
  }
}

.returnLink {
  font-family: Popins-semiBold, sans-serif;
  font-weight: 600;
  font-size: 14px;
  display: block;
  margin-top: 16px;
  letter-spacing: 0.2px;
}

.step2_formContainer {
  margin-top: 24px;
}

.step2_sendInfo {
  color: var(--Grey-600);
}

.setp2_submitBtn {
  min-width: fit-content;
  width: 100%;
  & span {
    margin: auto;
  }
}

.setp2_resendBtn {
  display: contents;
  font-family: Popins-semiBold, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: var(--Primary-700);
}

@media screen and (min-width: 576px) {
  .brandImg {
    min-height: 270px;
  }

  .loginButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .loginBtn {
    max-width: 150px;
  }

  .forgotBtn {
    margin-top: unset;
  }
}

@media screen and (min-width: 1024px) {
  .loginWrapper {
    height: 100vh;
  }

  .loginContainer {
    width: 100%;
    display: flex;
    margin: unset;
    max-width: none;
    height: calc(100% - 48px);
    padding: 0;
  }

  .logoWraper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--Primary-100);
    max-width: 539px;
    width: 100%;

    & p {
      max-width: 430px;
      margin-top: 50px;
    }
  }

  .brandImg {
    margin: unset;
    max-width: none;
    max-height: none;
  }

  .formContainer {
    max-width: 340px;
    width: 100%;
    margin: auto;
  }

  .loginButtons {
    margin-top: 40px;
  }

  .loginBtn {
    width: max-content;
  }

  .adBtn {
    margin-top: 0;
  }

  .forgotPasswordBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .resetPwdBtn {
    width: max-content;
  }

  .returnLink {
    margin-top: 0;
  }

  .step2_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .setp2_submitBtn {
    width: max-content;
  }
}

@media screen and (min-width: 1024px) {
  .forgotPasswordBtns {
    margin-top: 32px;
  }
}
