.playerWrapper {
  width: 100%;
  margin: 0;
}

.trackNameWrapper {
  min-width: 368px;
}

.trackName {
  text-align: center;
  color: var(--Neutral-Grey-800, #303840);
  font-size: 14px;
  font-weight: 600;
  margin-right: 40px;
}
.closeIcon {
  border: none;
  box-shadow: none;
}
.playerWrapper .closeIcon:hover {
  background-color: transparent;
}

:global(.closeBtn) {
  margin-left: 16px;
}

:global(.playerTooltip) {
  padding: 0;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04),
    0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
}

:global(.rhap_container) {
  padding: 16px 32px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px -1px 4px 0px rgba(0, 0, 0, 0.06);
}

:global(.rhap_controls-section) {
  display: none;
}

:global(.rhap_main-controls) {
  margin-right: 16px;
}

:global(.rhap_main-controls-button) {
  font-size: 24px;
  width: 24px;
  height: 24px;
}

:global(.rhap_time) {
  color: var(--Grey-800, #303840);
  font-size: 14px;
  width: 40px;
}

:global(.rhap_total-time) {
  margin-right: 16px;
}

:global(.rhap_progress-container) {
  margin: 0 12px;
}

:global(.rhap_progress-indicator) {
  background-color: var(--Primary-700);
  width: 16px;
  height: 16px;
  top: -6px;
}
:global(.rhap_progress-filled) {
  background-color: var(--Primary-700);
}
:global(.rhap_volume-indicator) {
  background-color: var(--Primary-700);
}
