.dropdownBtnItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 93%;
}

.chipsList {
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
}

.moreItems {
  height: 28px;
  background-color: var(--Grey-50);
  margin-left: 2px;
  border: 1px solid #d7dbe3;
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--Grey-600);
  font-family: Poppins-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  width: 100%;
  text-align: center;
}

.title {
  font-family: Poppins-SemiBold;
  color: var(--Grey-800);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
}
