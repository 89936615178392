.pageHeader {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--White);
  z-index: 9;
}

.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--Grey-200);
  padding: 8px 20px;
}

.pageHeaderContent {
  display: flex;
  align-items: center;
  margin-inline: 20px;
  padding-block: 10px;
  border-bottom: 1px solid var(--Grey-100);
}

.pageHeaderContent_left {
  margin-right: 20px;
}

.pageHeaderContent_title,
.pageHeaderContent_status {
  display: flex;
  align-items: center;
}

.pageHeaderContent_status {
  margin-top: 4px;
}

.statusText {
  color: var(--Error-600);
}

.absentText {
  color: #b78a00;
  margin-left: 8px;
}

.pageSubtitle {
  padding: 24px;
  color: var(--Grey-600);

  & p {
    max-width: 980px;
  }
}

.actionContainer {
  margin-left: auto;
}

.pageIcon {
  justify-content: center;
  margin-right: 8px;
}

.pageTitle {
  font-family: Poppins-SemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  word-break: break-word;
}

:global(.save-button) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--White);
  padding: 10px 20px;
  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.15);
  z-index: 9;
}

@media screen and (min-width: 1024px) {
  .pageHeader {
    padding: 12px 24px;
    border-bottom: 1px solid var(--Grey-100);
  }

  .pageIcon {
    margin: 0 12px 0 0;
  }

  .pageHeaderContent {
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    border-bottom: none;
  }

  .actionContainer {
    display: flex;
  }

  .pageHeaderContent_left {
    display: flex;
  }
  .pageHeaderContent_status {
    margin-top: 0;
    margin-left: 8px;
  }
}
