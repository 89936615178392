.controlButtonsWrapper {
  display: flex;
}

.controlButtonsWrapper button,
.cancelSubscriptionFooter button {
  margin-right: 8px;
}

.formInputsWrapper {
  display: flex;
  flex-wrap: wrap;
}

.contentWrapper {
  display: flex;
  max-width: 376px;
}

.contentWrapper input,
.contentWrapper div[role="button"] {
  min-width: 376px;
}

.additionalInfo {
  color: var(--Grey-600);
}

.smallAdditionalInfo {
  font-size: 12px;
}
