.scheduleItem {
  max-width: 784px;
  min-width: 778px;
  width: 100%;
  border: 1px solid var(--Grey-200);
  padding: 12px 16px;
  margin-bottom: 16px;
}

.buttonsWrapper {
  flex-grow: 0;
  margin: 40px 0 0 42px;
}

.slash,
.oldSlash {
  width: 10px;
  border: 1px solid var(--Grey-200);
}

.slash {
  margin: 40px 8px 0;
}

.oldSlash {
  margin: 0 8px;
}

.viewSlash {
  margin: auto;
}

.intervalWrapper {
  display: flex;
  margin: 20px 0 0 32px;
}

.customView {
  margin: 0;
  width: 364px;
}

.dataWrapper {
  display: flex;
  align-items: center;
}

.dataWrapper span {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
