/* stylelint-disable selector-class-pattern */

/* #region Restyle reactjs-popup */

:global(.popupTooltipCustom-content.popup-content) {
  padding: 4px 8px;
  border: 1px solid var(--Grey-800);
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  width: auto;
  max-width: 240px;
  line-break: anywhere;
  z-index: 9999 !important;
}

:global(.popupTooltipCustomDark-content.popup-content) {
  background: var(--Grey-700);
  background-color: var(--Grey-700);
  color: var(--White);
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.04),
    0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
}

:global(.popupTooltipCustomLight-content.popup-content) {
  background: var(--White);
  background-color: var(--White);
  color: var(--Grey-700);
  border: none;
  box-shadow:
    0 5px 10px rgba(0, 0, 0, 0.07),
    0 3px 6px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.06);
}

:global(.popupTooltipCustomSmall-content.popup-content) {
  padding: 4px 8px !important;
}

:global(.popupTooltipCustomMedium-content.popup-content) {
  padding: 16px !important;
}

:global(.popupTooltipCustom-arrow) {
  display: none;
}

:global(.pac-container) {
  z-index: 9999999;
}

/* #endregion */
