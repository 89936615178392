.checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 40px;
  padding-left: 12px;
  justify-content: center;
  cursor: pointer;
}

.mainInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.emptyElem {
  width: 28px;
  flex-shrink: 0;
}

.checkboxLabel {
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--Grey-600);
}

.checkboxContainer {
  width: 16px;
  height: 16px;
  border-radius: 3.2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.04);
}

:global(.p-checkbox) {
  width: 16px;
  height: 16px;
}

:global(.p-checkbox .p-checkbox-box) {
  border: 1px solid #d7dbe3;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

:global(.p-checkbox.p-highlight .p-checkbox-box) {
  background-color: var(--Primary-700);
  border-color: var(--Primary-700);
}

:global(.p-checkbox-checked .p-disabled.p-highlight) {
  background-color: var(--Grey-400);
  border-color: var(--Grey-400);
}

:global(.p-checkbox-box.p-disabled) {
  background-color: var(--Grey-100);
  border-color: var(--Grey-100);
  border: 1px solid #d7dbe3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.04);
}

:global(.p-checkbox .p-checkbox-box .p-checkbox-icon) {
  transition-duration: 0.2s;
  color: var(--White);
  font-size: 11px;
  font-weight: bold;
}

.description {
  color: var(--Grey-600);
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  cursor: initial;
  margin-left: 28px;
}

.focused {
  background-color: var(--Grey-150);
}

:global(.p-hidden-accessible) {
  position: initial;
}

:global(.p-tree .p-tree-container .p-treenode:focus > .p-treenode-content) {
  box-shadow: none;
}

:global(
    .p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible)
      .p-checkbox-box
  ) {
  box-shadow: 0 0 4px 2px var(--Primary-700);
}
