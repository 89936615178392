.collapsibleItem {
  background: none;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 8px;
  text-align: left;
  color: var(--Grey-600);
  cursor: pointer;
}
.collapsibleItem:hover {
  color: var(--Primary-700);
  background: var(--Primary-50);
}

.collapsibleTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
}

.collapsibleTitleWrapperOpen {
  color: var(--Primary-700);
  background: var(--Primary-50);
}

.collapsibleItem:hover .chevronIcon path {
  stroke: var(--Primary-700);
}

.collapsibleItem:focus-visible {
  outline: none;
  box-shadow: none;
  background: var(--Primary-50);
}

.collapseContent {
  overflow: hidden;
  position: relative;
  transition: height 200ms ease-in-out;
}
