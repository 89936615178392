.roleWrapper {
  border: 1px solid #d7dbe3;
  border-radius: 4px;
  width: 784px;
}

.relatedItemWrapper {
  padding: 10px;
  border-top: 1px solid var(--Grey-200);
}

.relatedItemWrapper:first-of-type {
  border-top: none;
}

.contextBtn {
  cursor: pointer;
}
