.wrapper {
  padding-block: 15px;
  margin-inline: 18px 10px;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(4, minmax(auto, 178px)) 45px;
  row-gap: 10px;
  border-top: 1px solid var(--Grey-100);
}

.wrapper span {
  font-size: 14px;
}

.objectName {
  font-weight: 600;
  color: var(--Grey-800);
}

.relatedName {
  font-weight: 600;
  color: var(--Grey-800);
  font-size: 14px;
}

.relatedItemType {
  grid-column: 1/-1;
  grid-row: 1;
  display: flex;
  align-items: center;
}

.relatedItemType span {
  margin-left: 5px;
  color: var(--Grey-600);
}

.subscription {
  color: var(--Grey-800);
}

.noSubscription {
  color: var(--Error-600);
}

.removeBtn {
  align-self: flex-end;
  border: none;
  box-shadow: none;
  padding: 0 !important;
}
