.container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.tab {
  font-family: Poppins-SemiBold, sans-serif;
  padding: 8px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--Grey-600);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.tabIcon {
  margin-right: 12px;
}

.selectedTab {
  background-color: var(--Primary-50);
  color: var(--Primary-700);
}

.disabledTab {
  cursor: auto;
}
