.customTableCell {
  overflow: hidden;
}

.customTableCell .textWrapper {
  width: 100%;
  justify-content: start;
}

.customTableCell .textWrapper > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
