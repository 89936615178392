:global(.popup-overlay) {
  background-color: rgba(0, 0, 0, 0.25);
}

:global(.popup-content) {
  width: 100%;
  max-width: 1000px;
  border-radius: 4px;
  padding: 5px 0;
}

:global(.mt-8-content) {
  margin-top: 8px !important;
}

:global(.alarmDetails) {
  font-family: Poppins-Regular, sans-serif;
  width: 960px;
}

:global(.alarmDetailsContainer) {
  max-height: calc(75vh - 150px) !important;
}

:global(.object-subscription-modals-content) {
  width: 700px;
}

:global(.camera-modal),
:global(.location-modal) {
  min-width: 848px;
  max-width: 848px;
}

.container {
  min-width: 700px;
  max-height: 90vh;

  --error-color-local: var(--Error-600);
}

.inner {
  padding: 0 14px 16px 32px;
  margin-right: 18px; /* for scroll */
  max-height: calc(90vh - 150px);
  overflow: auto;
}

.modalHeader {
  padding: 32px 32px 24px 32px;
}

.title {
  font-family: Poppins-SemiBold;
  color: var(--Grey-800);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.1px;
}

.extraTitleText {
  opacity: 0.5;
}

.closeIconWrapper {
  position: absolute;
  right: 32px;
  top: 38px;
  cursor: pointer;
}
/* stylelint-disable-next-line selector-class-pattern */
:global(.popupModalCustom.popup-content) {
  background-color: white;
  min-width: 700px;
  max-height: 90vh;
  padding: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07), 0 3px 6px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border: none;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  1% {
    transform: scale(0.96) translateY(50px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.modalFooter {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  padding: 32px;
}

.modalFooterWithShadow {
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.06);
  position: relative;
}

.mainBtns {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.approveBtn {
  margin-right: 8px;
}

.errorLabel {
  font-style: normal;
  font-size: 14px;
  font-family: Poppins-Regular;
  letter-spacing: 0.5px;
  line-height: 24px;
  font-weight: 600;
  color: var(--error-color-local);
  box-sizing: border-box;
}
