.divider {
  width: 100%;
  height: 1px;
  background-color: var(--Grey-150);
  flex-shrink: 0;
}

.verticalDivider {
  display: flex;
  align-self: stretch;
  width: 1px;
  background-color: var(--Grey-150);
}
