.freeText input {
  width: 100%;
}

.multiSelect ul {
  width: 100%;
  padding: 5px 6px 5px 12px;
  column-gap: 0;
  row-gap: 6px;
  & input:focus-visible {
    outline: none;
    box-shadow: none;
  }
}

.singleChoiceDisabled li:last-child {
  display: none;
}

.autocompliteWrapper {
  display: flex;
  flex-direction: column;
}

.filledBorder ul,
.filledBorder:not(:has(ul)) input {
  border: 1px solid var(--Grey-300);
}

.validationError ul,
.validationError:not(:has(ul)) input {
  border: 1px solid var(--Error-400);
}

:global(.p-autocomplete .p-focus) {
  box-shadow: 0 0 4px 2px var(--Primary-700);
}

:global(.p-autocomplete-input-token) {
  flex: 1 1 0%;
}

:global(.p-autocomplete-token) {
  background: var(--Grey-50);
  border: 1px solid var(--Grey-200);
  border-radius: 4px;
  color: var(--Grey-900);
  line-height: 20px;
  flex: 0 1 auto;
  margin-right: 6px;
  padding: 3px 8px;
  font-size: 12px;
}

:global(li[data-pc-section="emptymesage"]) {
  color: var(--Grey-600);
}

:global(.p-autocomplete-item) {
  color: var(--Grey-900);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2px;
}

:global(.p-autocomplete-item[aria-selected="true"]) {
  background: linear-gradient(
    270deg,
    var(--Grey-50) 0%,
    var(--Primary-50) 60.89%
  );
  color: var(--Primary-700);
  &::after {
    content: "";
    position: absolute;
    right: 5px;
    width: 24px;
    height: 24px;
    background: url("../../assets/icons/check.svg") no-repeat;
  }
}

:global(.p-highlight[aria-selected="false"]) {
  background: var(--Grey-100);
}

:global(.p-autocomplete-panel .p-autocomplete-items) {
  position: relative;
  padding: 4px;
}

:global(.p-autocomplete-loader) {
  display: none;
}

:global([data-pc-section="removetokenicon"]) {
  font-size: 12px;
  color: var(--Grey-400);
}
:global([data-pc-section="removetokenicon"]):hover {
  color: var(--Primary-700);
}
