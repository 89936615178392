.nameWrapper {
  font-size: 16px;
  margin-right: 8px;
}

.phoneWrapper {
  color: var(--Grey-600);
  font-size: 16px;
}

.alarmCodeWrapper {
  font-weight: 600;
}

.alarmInfoWrapper {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.alarmInfoWrapper > div {
  flex: 0 0 33%;
}

.classificationHeader {
  color: var(--Grey-600);
}

.codeOverrideFooter {
  display: flex;
}

.codeOverrideFooter button {
  margin-right: 8px;
}

.link:hover {
  text-decoration: underline;
}

.objectLink {
  font-weight: 600;
  font-size: 16px;
  text-decoration: underline;
  color: var(--Primary-700);
  margin-right: 8px;
}

.objectLink:hover,
.arcLink:hover {
  cursor: pointer;
}

.arcLink {
  font-weight: 600;
  font-size: 14px;
  color: var(--Grey-800);
}
