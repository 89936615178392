.noRespondentGroups {
  display: flex;
  justify-content: center;
  height: 100px;
  width: 100%;
  border: 1px solid var(--Grey-200);
}

.noRespondentGroups p {
  margin: auto;
  color: var(--Grey-500);
  font-size: medium;
}

.bold {
  font-weight: bold;
  cursor: pointer;
}
