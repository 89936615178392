.badge {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  background: var(--White);
  color: var(--Grey-900);
  overflow: hidden;
  font-family: Poppins-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.badgeSecondary {
  background: var(--Grey-100);
  color: var(--Grey-600);
}

.badgeInfo {
  background: var(--Primary-100);
}

.badgeSuccess {
  background: var(--Success-50);
  color: var(--Success-600);
}

.badgeWarning {
  background: var(--Warning-50);
}

.badgeError {
  background: var(--Error-50);
}

.badgeWithBorder {
  border: 1px solid var(--Grey-200);
}

.badgeSecondary.badgeWithBorder {
  border: 1px solid var(--Grey-200);
}

.badgeInfo.badgeWithBorder {
  border: 1px solid var(--Primary-300);
}

.badgeSuccess.badgeWithBorder {
  border: 1px solid var(--Success-400);
}

.badgeWarning.badgeWithBorder {
  border: 1px solid var(--Warning-200);
}

.badgeError.badgeWithBorder {
  border: 1px solid var(--Error-300);
}

.badgeWithHighlightedText {
  font-weight: 600;
}

.badgeSecondary.badgeWithHighlightedText {
  color: var(--Grey-600);
}

.badgeInfo.badgeWithHighlightedText {
  color: var(--Primary-700);
}

.badgeSuccess.badgeWithHighlightedText {
  color: var(--Success-600);
}

.badgeWarning.badgeWithHighlightedText {
  color: var(--Warning-700);
}

.badgeError.badgeWithHighlightedText {
  color: var(--Error-600);
}

.badgeInfo .badgeIcon path {
  stroke: var(--Primary-700);
}

.badgeSuccess .badgeIcon path {
  stroke: var(--Success-700);
}

.badgeWarning .badgeIcon path {
  stroke: var(--Warning-700);
}

.badgeError .badgeIcon path {
  stroke: var(--Error-500);
}

.badgeTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
