.indicatorRow {
  margin-top: 8px;
  width: 100%;
  gap: 6px;
}

.indicator {
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: var(--Grey-200);
}

.indicatorText {
  font-size: 10px;
  line-height: 20px;
  width: 100%;
  display: flex;
  justify-content: end;
  color: var(--Grey-600);
}

.weak {
  background: var(--Error-400);
}

.medium {
  background: rgba(242, 201, 76, 1);
}

.strong {
  background: rgba(98, 162, 112, 1);
}
