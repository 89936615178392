.containerFixed {
  position: relative;
  background-color: var(--White);
  box-sizing: border-box;
  box-shadow:
    0 5px 10px rgba(0, 0, 0, 0.07),
    0 3px 6px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.container {
  position: relative;
  background-color: var(--White);
  padding: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  max-height: 90vh;
  overflow: auto;
}

.content {
  overflow: auto;
  padding-left: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
}

.footer {
  padding-left: 32px;
  padding-bottom: 32px;
  padding-top: 16px;
  padding-right: 32px;
}

.closeButtonFixed {
  margin-top: 32px;
  margin-right: 32px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.closeButton {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 32px;
  right: 32px;
}

.closeButton:hover {
  cursor: pointer;
  opacity: 0.8;
}
