body {
  overflow: hidden;
}

.app {
  height: 100dvh;
}

@media screen and (min-width: 576px) {
  .app {
    height: 100vh;
  }
}
