.subscriptionItem {
  display: flex;
  max-width: 376px;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--Grey-200);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px -1px 4px 0px rgba(0, 0, 0, 0.06);
}

.subscriptionItem span {
  font-size: 14px;
  line-height: 24px;
  color: var(--Grey-800);
}

.periodItem {
  display: flex;
  padding: 4px 8px;
  background: var(--Grey-50);
  border-radius: 4px;
  border: 1px solid var(--Grey-200);
  align-items: center;
  gap: 8px;
}
.periodItem span {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: var(--Grey-900);
}

.subHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
