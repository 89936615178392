/* Generic */

.button {
  --primary-color-local: var(--White, #fff);
  --primary-background-local: var(--Primary-700, #00746d);
  --primary-background-hover-local: var(--Primary-600, #4784e4);
  --primary-background-active-local: var(--Primary-800, #1f4790);
  --secondary-color-local: var(--Grey-800, #303840);
  --secondary-border-local: var(--Grey-200, #d7dbe3);
  --secondary-color-hover-local: var(--Grey-600, #606b77);
  --secondary-background-local: var(--White, #fff);
  --secondary-background-hover-local: var(--Grey-50, #fbfcfe);
  --secondary-background-active-local: var(--Grey-100, #eceef3);
  --destructive-color-local: var(--Error-600, #ba282e);
  --destructive-background-local: var(--White, #fff);
  --destructive-background-hover-local: var(--Error-50, #ffedf0);
  --destructive-background-active-local: var(--Error-100, #ffcad0);
  --popup-color-local: var(--Primary-700, #00746d);
  --popup-border-local: var(--Grey-200, #d7dbe3);
  --popup-background-local: var(--White, #fff);
  --popup-background-hover-local: var(--Grey-50, #fbfcfe);
  --popup-background-active-local: var(--Grey-100, #eceef3);
  --link-color-local: var(--Primary-700, #00746d);
  --link-color-hover-local: var(--Primary-700, #00746d);
  --link-color-active-local: var(--Primary-700, #00746d);

  font-family: Poppins-SemiBold, sans-serif;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 0.2px;
  white-space: nowrap;
  margin: 0;
  overflow: hidden;

  & span {
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.button:hover:not(:disabled) {
  cursor: pointer;
}

.button:disabled {
  opacity: 0.5;
}

.button svg {
  width: 24px;
  height: 24px;
  margin: 0 4px;
  flex: none;
  flex-grow: 0;
}

/* Common Styles */
.button.buttonExtraSmall {
  padding: 4px 12px;
}

.buttonExtraSmall svg {
  width: 20px;
  height: 20px;
}

.buttonSmall {
  padding: 8px 12px;
}

.buttonSmall.buttonWithIconAndText {
  padding: 7px 12px 7px 7px;
}

.buttonSecondary.buttonWithIconAndText,
.buttonDestructive.buttonWithIconAndText,
.buttonPopup.buttonWithIconAndText {
  padding: 7px 11px 7px 7px;
}

.buttonSecondary.buttonWithIcon,
.buttonDestructive.buttonWithIcon,
.buttonPopup.buttonWithIcon {
  padding: 7px;
}

.wrappedText {
  max-width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

/* Primary */

.buttonPrimary {
  color: var(--primary-color-local);
  background: var(--primary-background-local);
  border-width: 0;
}

.buttonPrimary:hover:not(:disabled) {
  background: var(--primary-background-hover-local);
}

.buttonPrimary:active:focus {
  background: var(--primary-background-active-local);
}

/* Active */

.buttonActive {
  border: 1px solid var(--Primary-700);
  color: var(--Primary-700);
  background: var(--White);
}

.buttonActive:hover:not(:disabled) {
  background: var(--Grey-50);
}

.buttonActive:active:focus {
  background: var(--Grey-100);
}

/* Secondary */

.buttonSecondary {
  border: 1px solid var(--secondary-border-local);
  color: var(--secondary-color-local);
  background: var(--secondary-background-local);
  padding: 7px 12px;
}

.buttonSecondary:hover:not(:disabled) {
  background: var(--secondary-background-hover-local);
  color: var(--secondary-color-hover-local);
}

.buttonSecondary:active:focus {
  background: var(--secondary-background-active-local);
}

/* Destructive */

.buttonDestructive {
  border: 1px solid var(--destructive-color-local);
  color: var(--destructive-color-local);
  background: var(--destructive-background-local);
}

.buttonDestructive:hover:not(:disabled) {
  background: var(--destructive-background-hover-local);
}

.buttonDestructive:active:focus {
  background: var(--destructive-background-active-local);
}

/* Popup */

.buttonPopup {
  border: 1px solid var(--popup-border-local);
  color: var(--popup-color-local);
  background: var(--popup-background-local);
}

.buttonPopup:hover:not(:disabled) {
  background: var(--popup-background-hover-local);
}

.buttonPopup:active:focus {
  background: var(--popup-background-active-local);
}

/* Link */

.buttonLink,
.buttonBorderLink {
  border-width: 0;
  box-sizing: border-box;
  color: var(--link-color-local);
  background: transparent;
  padding: 0;
}

.buttonBorderLink:hover:not(:disabled) {
  background-color: var(--Primary-50);
}

.buttonBorderLink:active:focus {
  background-color: var(--Primary-100);
}

.buttonBorderLink:disabled {
  color: var(--Grey-300);
  border-color: var(--Grey-300);
}

.buttonBorderLink {
  padding: 8px 12px;
  border: 1px solid var(--link-color-local);
}

.buttonLink:hover:not(:disabled) {
  color: var(--link-color-hover-local);
}

.buttonLink:active:focus {
  color: var(--link-color-active-local);
}

.buttonWithIcon {
  justify-content: center;

  & svg {
    margin: 0 4px 0 0;
  }

  & span {
    margin: 0;
  }
}

@media screen and (min-width: 1024px) {
  .buttonWithIcon {
    justify-content: initial;

    & svg {
      margin: 0 4px;
    }

    & span {
      margin: auto;
    }
  }
}
