.container {
  position: relative;
  background-color: var(--White);
  padding: 32px 0;
  border-radius: 4px;
}

.content {
  width: 591px;
}

.closeButton {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 32px;
  right: 0;
}

.closeButton:hover {
  cursor: pointer;
  opacity: 0.8;
}

.description {
  color: var(--Grey-600);
}

.label {
  font-family: Poppins-SemiBold, sans-serif;
  font-weight: 600;
}

.labelSmall {
  color: var(--Grey-600);
  font-size: 12px;
  line-height: 20px;
}

.dateFilterWrapper {
  padding: 16px;
  border: 1px solid var(--Grey-200);
  border-radius: 4px;
}

.customColumnsWrapper {
  display: flex;
  flex-wrap: wrap;
}
