.multiStringWrapper {
  width: 100%;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  background-color: var(--Grey-50);
  border: 1px solid #d7dbe3;
  border-radius: 4px;
  height: 28px;
}

.name,
:global(.p-chips .p-chips-multiple-container .p-chips-input-token input) {
  color: var(--Grey-800);
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  margin-right: 12px;
}

:global(
    .p-chips .p-chips-multiple-container .p-chips-input-token input::placeholder
  ) {
  color: var(--Grey-600);
}

:global(
    .p-chips
      .p-chips-multiple-container:has(.p-chips-input-token input:focus-visible)
  ) {
  outline: none;
  box-shadow: 0 0 4px 2px var(--Primary-700);
}

:global(.p-chips-input-token input:focus-visible) {
  outline: none;
  box-shadow: none;
}

.deleteIcon {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}

.validationErrorContainer {
  border-color: blueviolet;
}

.validationError {
  font-size: 12px;
  line-height: 20px;
  color: var(--Error-600);
}

:global(.p-chips-multiple-container .p-chips-token-icon.pi-times-circle) {
  display: none;
}

:global(.p-chips-multiple-container .p-chips-token.p-highlight) {
  background-color: white !important;
  padding: 0 !important;
}

:global(.p-inputtext.p-chips-multiple-container) {
  width: 100%;
  overflow-x: auto;
  padding: 4px 12px !important;
  min-height: 40px;
  height: auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.04);
}

:global(.p-chips.p-component.p-inputwrapper-focus) {
  box-shadow: none !important;
}

:global(.p-inputtext.p-chips-multiple-container.p-focus) {
  border-color: var(--Primary-700) !important;
  outline: none;
  box-shadow: 0 0 0 1px var(--Primary-700) !important;
}

:global(.p-chips.p-component.p-inputwrapper) {
  display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
