.customSettingsContainer {
  display: flex;
  border: 2px solid var(--Grey-200);
  border-radius: 4px;
  padding: 16px;
  align-items: center;
}

.customSettingsFields {
  width: 100%;
  flex: 1 1 auto;
}

.notVisible {
  display: none;
}
