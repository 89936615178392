.buttonWrapper {
  cursor: pointer;
  position: relative;
}

.buttonWrapper:has(:focus-visible) {
  opacity: 1;
  outline: none;
  box-shadow: 0 0 4px 2px var(--Primary-700);
}

.buttonTitle {
  font-weight: 600;
}

.buttonContent {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--Grey-200);
  border-radius: 4px;
  text-align: center;
}

.buttonContent p {
  margin-left: 12px;
  color: var(--Primary-700);
  font-weight: 600;
}

.fileInput {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.imgContainer {
  position: relative;
}

.imgContainer .hoverContent {
  position: absolute;
  background: #d7dbe380;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  border: 1px solid var(--Primary-500);
  border-radius: 4px;
}

.imgPopup {
  position: absolute;
  z-index: 10;
  visibility: hidden;
  background: var(--White);
  border-radius: 4px;
  border: 1px solid var(--Grey-200);
  box-shadow: 0px 2px 6px 0px #1b20281f;
}

.imgContainer:hover .imgPopup,
.imgContainer:hover .hoverContent {
  visibility: visible;
}

.updateLabel {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
  & span {
    font-weight: normal;
  }
  & input {
    display: none;
  }
}

.updateLabel:hover {
  color: var(--Primary-700);
  & svg path {
    stroke: var(--Primary-700);
  }
}

@media screen and (min-width: 576px) {
  .imgPopup {
    right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .buttonContent {
    height: 120px;
    width: 120px;
  }

  .buttonWrapper:hover::after {
    content: "";
    position: absolute;
    top: -50%;
    transform: translateY(50%);
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: #d7dbe380;
    border: 1px solid var(--Primary-500);
    border-radius: 4px;
    z-index: 10;
  }

  .imgPopup {
    right: initial;
    left: 50%;
    transform: translateX(-50%);
  }
}
