.iconWithInfoContainer {
  display: flex;
}

.iconWrapper {
  text-align: center;
  margin-right: 12px;
}

.iconBackground {
  width: 40px;
  height: 40px;
  background-color: var(--Primary-50);
}

.iconBackground svg {
  height: 100%;
}

.infoBodyWrapper {
  font-size: 14px;
  font-weight: 600;
}

.infoHeaderWrapper {
  line-height: 20px;
  color: var(--Grey-600);
}

.iconHeader {
  margin-bottom: -4px;
}

.iconTooltipHeader {
  font-weight: 600;
}

.iconTooltipText {
  font-weight: 400;
}
