.headerLink {
  padding: 20px 20px 8px;
  border-bottom: 1px solid var(--Grey-200);
  position: sticky;
  top: 0;
  background: var(--White);
}

.headerLink_logo:focus-visible {
  box-shadow: none;
  & img {
    box-shadow: 0 0 4px 2px var(--Primary-700);
  }
}

@media screen and (min-width: 1024px) {
  .headerLink {
    border-bottom: none;
    padding: 0;
    margin-bottom: 48px;
  }
}
