.container {
  min-width: 576px;
  position: relative;
  background-color: var(--White);
  padding: 32px;
  border-radius: 4px;
}

.closeButton {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 32px;
  right: 32px;
}

.closeButton:hover {
  cursor: pointer;
  opacity: 0.8;
}

.light {
  color: var(--Grey-600);
}

.semibold {
  font-family: Poppins-SemiBold;
  font-weight: 600;
}
