:global(.popupTableRowSelection-overlay) {
  background: transparent;
  width: 0;
  height: 0;
  position: static;
}

:global(.popupTableRowSelection-content) {
  z-index: 999 !important;
  position: fixed !important;
  left: 50% !important;
  transform: translateX(-50%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07), 0 3px 6px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 0;
  border: 1px solid var(--Grey-200);
  width: calc(100% - 40px);
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--Grey-800);
}

:global(.popupTableRowSelectionTopCenter-content) {
  top: 40px !important;
}

:global(.popupTableRowSelectionBottomCenter-content) {
  bottom: 40px !important;
}

:global(.popupTableRowSelection-arrow) {
  display: none;
}

:global(.batch-modal) {
  position: absolute;
  bottom: 40px;
}

:global(.batch-modal .p-dialog-footer) {
  padding: 16px;
}

.popupHeader {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid var(--Grey-100);
  font-weight: 600;
}

.batchModal {
  padding: 16px 0;
}

.popupBody {
  padding: 12px 16px;
}

.bodyRow {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;

  & > :nth-child(3) {
    grid-column: 1 / -1;
  }
}

@media screen and (min-width: 576px) {
  :global(.popupTableRowSelection-content) {
    width: 630px;
  }

  .bodyRow {
    display: flex;
    justify-content: space-between;
  }
}
