.linkButton {
  background: none;
  border: none;
  padding: 0;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.actionButton {
  margin-right: 8px;
}

.inputHeader {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  font-weight: 600;
}

.editFormBlockWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fit,1fr);
  column-gap: 16px;
  row-gap: 16px;
  width: 100%;
}
