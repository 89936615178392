.list {
  border: 1px solid var(--Grey-150);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.markedList {
  display: flex;
  flex-direction: column;
  justify-tracks: center;
}

.itemText {
  color: var(--Grey-800);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin-left: 8px;
}
