.wrapper {
  background: var(--Warning-50);
  border: 1px solid var(--Warning-200);
  color: var(--Warning-700);
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.alertIcon path {
  stroke: var(--Grey);
}

.text {
  color: var(--Grey);
}

.button {
  height: 32px;
}
