.wrapper {
  width: 100%;
  height: 100%;
}

.container {
  max-width: 768px;
  width: 100%;
}

.content {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px 60px;
  overflow: auto;
}

.organizationContent {
  box-sizing: border-box;
  width: 100%;
  padding: 24px 0 0;
  overflow: auto;
}

.tableContainer {
  width: 100%;
}

.settingsRow {
  width: 100%;
  flex: 0;
  justify-content: flex-start;
}

.filtersRow {
  align-items: flex-start;
}

.treeFilter {
  width: 205px;
}

.shortDateCell {
  flex-direction: row;
  justify-content: flex-end;
}

.noContentPadding {
  padding: 0;
}

.organizationLink {
  font-weight: 600;
  color: var(--Grey-800);
}

.organizationLink:hover {
  color: var(--Primary-800);
}

.selectAllWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--Grey-150);
  width: 100%;
  height: 40px;
}

.selectAllWrapper span {
  font-size: 14px;
}

:global(.p-overlaypanel-content) {
  padding: 16px;
}

:global(.actions-overlay) {
  width: min-content;
}

:global(.actions-overlay .p-overlaypanel-content) {
  padding: 5px 0;
}

:global(.croped-text) {
  overflow: hidden;
  text-overflow: ellipsis;
}

:global(.color-grey-300) {
  color: var(--Grey-300);
}

@media screen and (min-width: 576px) {
  .content {
    padding: 24px 24px 32px;
  }
}
