.organizationsCheckbox {
  width: 48%;
  color: var(--Grey-800);
}

.priceIcon {
  display: block;
  width: 100%;
  height: 100%;
  background: url("../../../ui-lib/assets/icons/priceDollarIcon.svg") no-repeat;
  margin-top: 4px;
  margin-right: -25px;
}

.rowWithValidation {
  align-items: flex-start;
}
