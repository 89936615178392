/* Rewite colors also for path as they might have stroke color set and setting stroke fro SVG won't rewrite it. */

.grey,
.grey path {
  stroke: #7a8692;
}

.black,
.black path {
  stroke: #000;
}

.red,
.red path {
  stroke: red;
}

/* Buttons */
.buttonPrimary,
.buttonPrimary path {
  stroke: #fff;
}

.buttonActive,
.buttonActive path {
  stroke: var(--Primary-700);
}

.buttonSecondary,
.buttonSecondary path {
  stroke: var(--Grey-400);
}

.buttonDestructive,
.buttonDestructive path {
  stroke: var(--Error-600);
}

.buttonPopup,
.buttonPopup path {
  stroke: var(--Primary-700);
}

.blue,
.blue path {
  stroke: var(--Primary-700);
}

.primary700,
.primary700 path,
.primary700 rect {
  stroke: var(--Primary-700);
}

.grey100,
.grey100 path {
  stroke: var(--Grey-100);
}

.grey300,
.grey300 path {
  stroke: var(--Grey-300);
}

.grey400,
.grey400 path {
  stroke: var(--Grey-400);
}

.grey400Hover:hover,
.grey400Hover:hover path {
  stroke: var(--Primary-700);
}

.grey500,
.grey500 path {
  stroke: var(--Grey-500);
}

.grey800,
.grey800 path {
  stroke: var(--Grey-800);
}

.warning700,
.warning700 path {
  stroke: var(--Warning-700);
}

.error500,
.error500 path {
  stroke: var(--Error-500);
}

.error400,
.error400 path {
  stroke: var(--Error-400);
}

.success600,
.success600 path {
  stroke: var(--Success-600);
}

.fillPrimary700,
.fillPrimary700 path {
  fill: var(--Primary-700);
}

.fillGrey,
.fillGrey path,
.fillGrey circle {
  fill: var(--Grey-300);
}

.Grey400,
.Grey400 path {
  stroke: var(--Grey-400);
}

.white,
.white path {
  stroke: white;
}

.clickable {
  cursor: pointer;
}
