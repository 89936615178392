.settingsAccordion {
  background: var(--Grey-50);
}

.settingsAccordion > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin: 1rem 0.5rem;
  align-items: end;
}

.settingsBoxForm {
  background: white;
  padding: 0 1rem 1rem;
  border: 1px solid var(--Grey-200);
  border-radius: 4px;
  min-height: 185px;
}

.settingsBoxForm > div {
  border: none;
}
