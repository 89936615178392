.tabsContainer {
  padding: 4px;
  background-color: var(--Grey-200);
  border-radius: 4px;
  max-height: 40px;
  width: 100%;

  & button {
    width: 100%;
  }
}

.switchTab {
  height: 32px;
  border: none;
  border-radius: 4px;
  background-color: var(--Grey-200);
  color: var(--Grey-800);
  font-family: Poppins-SemiBold, sans-serif;
  font-size: 14px;
  padding: 4px 16px;
  cursor: pointer;
}

.switchTab.active {
  background: var(--White);
}

@media screen and (min-width: 1024px) {
  .tabsContainer {
    width: initial;

    & button {
      width: initial;
    }
  }
}
