.colorPickerContainer {
  padding: 20px 20px 16px;
  width: 314px;
  background: #ffffff;
  border: 1px solid #d7dbe3;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.07), 0px 3px 6px rgba(0, 0, 0, 0.04),
    0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.widgetText {
  color: var(--Grey-600);
  letter-spacing: 0.2px;
  font-weight: 600;
}

.colorGrid {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.switchBtn {
  width: 20px;
  height: 20px;
}

.colorBtn {
  cursor: pointer;
  border: 1px solid #d7dbe3;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.07), 0px 3px 6px rgba(0, 0, 0, 0.04),
    0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.noColorBtn {
  background-image: linear-gradient(to bottom right, white 48%, red, white 52%);
}

.inputIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  margin-left: -7px;
  background: var(--Grey-200);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: var(--Grey-500);
}

.modalColorButtons {
  width: 32px;
  height: 32px;
}

.colorWidgetFooterBtns {
  display: flex;
  gap: 8px;
}
