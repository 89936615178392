.container {
  border-radius: 4px;
  background: var(--White);
  box-shadow: 0 1px 3px 0 #00000030;
  box-shadow: 0 1px 4px 0 #00000020;
}

:global(.stretch) {
  align-self: stretch;
}
