.container404 {
  background-color: var(--Primary-50);
  width: 100%;
  height: 100vh;
}

.wrapper404 {
  display: flex;
  justify-content: space-between;
  margin: 80px auto 0;
  column-count: 2;
  max-width: 800px;
}

.wrapper404 a img {
  margin-top: 50px;
}

.description404 {
  max-width: 600px;
  margin-top: 110px;
}

.wrapper404 img {
  max-width: 350px;
}

@media screen and (min-width: 1024px) {
  .wrapper404 {
    margin: 110px auto 90px;
    max-width: 1050px;
  }

  .wrapper404 img {
    max-width: auto;
  }
}
