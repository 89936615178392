.wrapper {
  width: 100%;
}

.content {
  width: 100%;
  justify-content: center;
  margin-bottom: 100px;
}

.chartPanel {
  flex: 1;
  align-self: stretch;
  margin: 0 32px 32px;
  overflow: hidden;
}

.searchButton {
  min-width: fit-content;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .content {
    justify-content: flex-start;
    margin: 0;
  }

  .searchButton {
    width: auto;
  }
}
