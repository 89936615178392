.toggleSwitchWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.toggleSwitchContainer {
  /* Set the deafult values */
  --switchtoggle-off-color: var(--Grey-200, #d7dbe3);
  --switchtoggle-off-disabled-color: var(--Grey-100, #ebeef4);
  --switchtoggle-on-color: var(--Primary-700, #00746d);

  background-color: transparent;
  width: 3rem;
}

.labelRowLabel {
  color: var(--Grey-600);
}

/* Toggle OFF */
.toggleSwitchContainer div[aria-checked="false"] span {
  background: var(--White);
  border: 1px solid var(--switchtoggle-off-color);
}

.toggleSwitchContainer div[aria-checked="false"] span::before {
  background: var(--switchtoggle-off-color);
}

/* Toggle ON */
.toggleSwitchContainer div[aria-checked="true"] span {
  background: var(--switchtoggle-on-color) !important;
}
:global(.p-disabled) {
  opacity: 1 !important;
}

:global(.p-inputswitch) {
  width: 3rem;
  height: 1.5rem;
  display: block;
}

:global(.p-inputswitch .p-inputswitch-slider::before) {
  width: 1rem;
  height: 1rem;
  margin-top: -0.5rem;
}

:global(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before) {
  transform: translateX(1.45rem);
}

.toggleSwitchDisabled div[aria-checked="true"] span {
  background: var(--switchtoggle-off-color) !important;
}

.toggleSwitchDisabled div[aria-checked="false"] span {
  background: var(--switchtoggle-off-disabled-color) !important;
}

:global(
    .p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:focus-visible)
      .p-inputswitch-slider
  ) {
  box-shadow: 0 0 5px 2px var(--Primary-700);
  outline: none;
}
