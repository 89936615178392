/* stylelint-disable selector-class-pattern */

/* #region primereact Calendar styles overrides */

/* Date picker */
:global(.p-overlaypanel) {
  max-width: 312px;
}
:global(.p-focus .p-inputtext) {
  box-shadow: none;
}
:global(.p-overlaypanel::before) {
  content: none;
}
:global(.p-overlaypanel::after) {
  content: none;
}
:global(.p-calendar) {
  width: 100%;
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--Grey-600);
}

:global(.p-calendar .p-inputtext) {
  transition: none !important;
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.2px;
}

:global(.p-calendar .p-inputtext:disabled) {
  background-color: var(--Grey-100) !important;
}

:global(.p-calendar .p-inputtext:enabled:hover) {
  border-color: var(--Grey-200);
}

:global(.p-datepicker .p-datepicker-header .p-datepicker-title select),
:global(.p-datepicker .p-datepicker-header .p-datepicker-title option) {
  color: var(--Grey-800);
  font-family: Poppins-Regular, sans-serif;
  font-weight: 600;
  font-size: 14px;
  border: none;
}

:global(.p-datepicker.p-component:not(.p-datepicker-timeonly)) {
  color: var(--Grey-800);
  font-family: Poppins-Regular, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 16px;
  min-width: auto !important;
  width: 312px !important;
}

:global(.p-datepicker.p-component .p-datepicker-group-container) {
  padding: 0;
  margin: 0;
}

:global(.p-datepicker.p-component .p-datepicker-group) {
  padding: 0;
  margin: 0;
}

/* header */
:global(.p-datepicker.p-component .p-datepicker-header) {
  margin: 0;
  padding: 1px 1px 16px 0;
  border-bottom: 1px solid var(--Grey-100);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

:global(.p-datepicker.p-component .p-datepicker-calendar-container) {
  display: flex;
  justify-content: center;
}

:global(.p-datepicker.p-component .p-datepicker-header .p-datepicker-prev),
:global(.p-datepicker.p-component .p-datepicker-header .p-datepicker-next) {
  color: var(--Grey-600);
  border-radius: initial;
  border: none;
  transition: none;
  width: 24px;
  height: 24px;
  padding: 0;
}

:global(
    .p-datepicker.p-component .p-datepicker-header .p-datepicker-prev:hover
  ),
:global(
    .p-datepicker.p-component .p-datepicker-header .p-datepicker-next:hover
  ) {
  color: var(--Grey-600) !important;
  background: none !important;
  box-shadow: none !important;
}

:global(
    .p-datepicker.p-component
      .p-datepicker-header
      .p-link
      .p-datepicker-prev-icon
  ),
:global(
    .p-datepicker.p-component
      .p-datepicker-header
      .p-link
      .p-datepicker-next-icon
  ) {
  font-size: 16px;
}

:global(.p-datepicker.p-component .p-datepicker-header .p-datepicker-title) {
  margin: 0;
  line-height: 24px;
}
:global(
    .p-datepicker.p-component .p-datepicker-header .p-datepicker-title button,

  ) {
  font-family: Poppins-Regular, sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding: 0;
}
:global(
    .p-datepicker.p-component .p-datepicker-header .p-datepicker-title select,

  ) {
  padding: 0;
}

/* calendar */
:global(.p-datepicker.p-component table.p-datepicker-calendar) {
  font-size: inherit;
  margin: 15px 0 0 0;
  table-layout: fixed;
  width: 280px;
}

:global(.p-datepicker.p-component table.p-datepicker-calendar th),
:global(.p-datepicker.p-component table.p-datepicker-calendar td) {
  padding: 0 0;
  font-family: Poppins-Regular, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

:global(.p-datepicker.p-component table.p-datepicker-calendar th) {
  color: var(--Grey-600);
  box-sizing: border-box;
}

:global(.p-datepicker.p-component table.p-datepicker-calendar td) {
  color: var(--Grey-800);
  box-sizing: border-box;
}

:global(.p-datepicker.p-component table.p-datepicker-calendar th span),
:global(.p-datepicker.p-component table.p-datepicker-calendar td span) {
  width: 32px;
  height: 32px;
  transition: none;
  margin: 4px;
  border-radius: 4px;
}

:global(
    .p-datepicker.p-component
      table.p-datepicker-calendar
      td
      span:not(.p-disabled):not(.p-highlight):hover
  ) {
  background: var(--Primary-50) !important;
}

:global(.p-datepicker.p-component table.p-datepicker-calendar td .p-disabled) {
  color: var(--Grey-300) !important;
  opacity: 1;

  /* Temporary fix */
  background: none !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

:global(
    .p-datepicker.p-component
      table.p-datepicker-calendar
      td
      .p-disabled.p-highlight
  ) {
  background: var(--Primary-50) !important;
}

/* today */
:global(
    .p-datepicker.p-component table.p-datepicker-calendar td.p-datepicker-today
  ) {
  background: none;
  font-weight: bold;
}
:global(
    .p-datepicker.p-component
      table.p-datepicker-calendar
      td.p-datepicker-today
      span
  ) {
  color: var(--Primary-600);
  border: 1px solid var(--Primary-600);
  background: none;
}

:global(.p-datepicker table td > span:focus),
:global(
    .p-datepicker:not(.p-disabled)
      table
      td
      span:not(.p-highlight):not(.p-disabled):focus
  ) {
  box-shadow: 0 0 4px 2px var(--Primary-700);
  outline: none;
}

/* selected */
:global(
    .p-datepicker.p-component table.p-datepicker-calendar td span.p-highlight
  ) {
  background: var(--Primary-700);
  color: var(--White);
  position: relative;
}

/* time */
:global(.p-datepicker.p-component .p-timepicker) {
  border-top: 1px solid var(--Grey-100);
  margin: 16px 0 0 0;
  padding: 16px 0 0 0;
}

:global(.p-datepicker.p-component .p-timepicker .p-link) {
  color: var(--Grey-600) !important;
  width: 24px;
  height: 24px;
}

:global(.p-datepicker.p-component .p-timepicker .p-link:hover) {
  color: var(--Grey-600) !important;
  background: none !important;
  box-shadow: none !important;
}

:global(.p-datepicker.p-component .p-timepicker .p-hour-picker),
:global(.p-datepicker.p-component .p-timepicker .p-minute-picker),
:global(.p-datepicker.p-component .p-timepicker .p-ampm-picker) {
  padding: 0;
  margin: 0;
}

:global(.p-datepicker.p-component .p-timepicker .p-separator) {
  padding: 0 16px;
}

:global(.p-datepicker.p-component .p-timepicker .p-hour-picker .p-link span),
:global(.p-datepicker.p-component .p-timepicker .p-minute-picker .p-link span),
:global(.p-datepicker.p-component .p-timepicker .p-ampm-picker .p-link span) {
  font-size: 16px !important;
}

:global(.p-datepicker.p-component .p-timepicker .p-hour-picker span:not(.pi)),
:global(.p-datepicker.p-component .p-timepicker .p-minute-picker span:not(.pi)),
:global(.p-datepicker.p-component .p-timepicker .p-ampm-picker span:not(.pi)) {
  font-family: Poppins-Regular, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

/* Time picker only */
:global(.p-calendar.p-calendar-timeonly) {
  width: 100%;
  transition: none;
}

:global(.p-calendar.p-calendar-timeonly .p-inputtext) {
  transition: none;
}

:global(.p-datepicker.p-datepicker-timeonly) {
  padding: 0;
}

:global(.p-datepicker.p-datepicker-timeonly .p-timepicker) {
  padding: 10px;
  margin: 0;
}

/* #endregion */

/* #region Custom date picker popup */

/* #region reactjs-popup custom styles */

:global([data-popup="tooltip"].datePickerCustomPopup-overlay) {
  display: none;
}

:global([role="tooltip"].datePickerCustomPopup-content) {
  background-color: var(--White);
  color: var(--Grey-800);
  margin: 0;
  border: 1px solid var(--Grey-200);
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07), 0 3px 6px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.06);
  width: 350px;
  padding: 16px;
}

:global(.datePickerCustomPopup-arrow) {
  display: none;
}

/* #endregion */

/* #region primereact Calendar styles overrides */

:global(
    .datePickerCustomPopup__p-calendar
      .p-datepicker.p-component:not(.p-datepicker-timeonly)
  ) {
  border: none;
  border-radius: 0;
  padding: 0;
}

/* Change header styles to be able to position back button */
:global(
    .datePickerWithBackButtonCustomPopup__p-calendar
      .p-datepicker.p-component
      .p-datepicker-header
  ) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* #endregion */

.popupCalendarContainer {
  position: relative;
}

.popupTimeContainer {
  padding: 16px 0;
  margin: 16px 0 0 0;
  border-top: 1px solid var(--Grey-100);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popupButtonsContainer {
  padding: 16px 0 0 0;
  border-top: 1px solid var(--Grey-100);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.timeSeparatorLine {
  display: inline-block;
  margin: 0 4px;
  background-color: var(--Grey-200);
  height: 2px;
  width: 6px;
  min-width: 6px;
}

.popupCalendarBackButton {
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 1;
  box-shadow: none;
  border-width: 0;
  box-sizing: border-box;
  color: var(--Grey-800);
  background: transparent;
  font-family: Poppins-Regular, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

/* #endregion */

.inputValidationError:not(.p-inputwrapper-focus) {
  border: 1px solid var(--Error-400);
  border-radius: 4px;
}
