/* stylelint-disable selector-class-pattern */

.container {
  display: flex;
  flex-direction: column;
  height: 44px;
}

.container .stretch {
  width: 100%;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  margin-right: 40px;
  position: relative;
  width: 100%;
}

.itemName {
  font-family: Poppins-SemiBold, sans-serif;
  color: var(--Grey-600);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.openIconWrapper {
  margin-right: 4px;
  display: flex;
  align-items: center;
  border: 1px solid var(--Grey-200);
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.04);
  border-radius: 3.2px;
}

.childItem {
  margin-left: 12px;
}

.itemWithoutIcon {
  margin-left: 20px;
}

.checkIconWrapper {
  position: absolute;
  right: 0;
  top: 3px;
}

.selectCustomer {
  font-family: Poppins-SemiBold, sans-serif;
  color: var(--Grey-600);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

:global(body .p-treeselect-panel) {
  overflow: auto;
  white-space: nowrap;
  margin-top: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07), 0 3px 6px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: calc(100vw - 58px);
}

:global(.p-treeselect.p-component.p-inputwrapper) {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid var(--Grey-200);
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 4%);
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  height: 40px;
}

:global(.p-treeselect.p-component.p-inputwrapper.p-disabled) {
  background: var(--Grey-100);
  opacity: 1;
}

:global(.p-inputtext) {
  color: var(--Grey-800);
  border-color: var(--Grey-200);
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
}

:global(.p-tree .p-tree-container .p-treenode:focus) {
  outline: none;
}
:global(
    .p-tree .p-tree-container .p-treenode:focus-visible > .p-treenode-content
  ) {
  box-shadow: none;
  outline: var(--Primary-700) auto 1px;
}

:global(.p-treenode.p-treenode-leaf) {
  display: flex;
}

:global(body .p-tree-toggler:focus) {
  box-shadow: none !important;
}

:global(body .p-treeselect-label.p-placeholder),
:global(body .p-treeselect-label-container .p-treeselect-label),
:global(body .p-treenode-label) {
  padding: 0 !important;
  font-family: Poppins-SemiBold, sans-serif;
  color: var(--Grey-900) !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

:global(body .p-highlight .p-treenode-label) {
  color: var(--Primary-700) !important;
}

:global(body .p-treeselect-trigger-icon.p-clickable) {
  font-size: 10px;
}

:global(body .p-treeselect.p-component.p-inputwrapper .p-treeselect-trigger) {
  width: 10px;
}

:global(body .p-treeselect.p-component.p-inputwrapper.p-focus) {
  box-shadow: 0 0 4px 2px var(--Primary-700);
}

:global(body .p-treeselect-panel .p-treeselect-close) {
  display: none;
}

:global(
    body .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container
  ) {
  margin-right: 0;
}

:global(body .p-treeselect-panel .p-treeselect-header) {
  padding: 4px 4px 0;
  background: white;
  border-radius: 4px;
}

:global(body .p-treeselect-filter-container .p-treeselect-filter:focus) {
  border: 1px solid var(--Primary-700);
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.04);
}

:global(.p-tree .p-tree-container .p-treenode) {
  padding: 0;
}

:global(.p-tree .p-tree-container .p-treenode .p-treenode-content) {
  height: 40px;
}

:global(.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight) {
  background: linear-gradient(
    270deg,
    var(--Grey-50) 0%,
    var(--Primary-50) 60.89%
  );
  border-radius: 6px;
}

:global(.p-treenode-content.p-treenode-selectable.p-highlight::before) {
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  right: 6px; /* (32-20)/2 */
  mask-image: url("../../../ui-lib/assets/icons/check.svg");
  background-color: var(--Primary-700);
  mask-size: cover;
  content: "";
}

:global(.p-tree-container) {
  overflow: unset;
  width: 100%;
}

:global(.p-tree.p-component.p-tree-selectable) {
  padding: 4px;
  display: flex;
  flex: 1;
}

:global(.p-treenode-content.p-treenode-selectable) {
  width: 100%;
  position: relative;
  margin-bottom: 2px;
}

:global(.p-treeselect .p-treeselect-label.p-placeholder) {
  color: var(--Grey-600) !important;
}

:global(.p-treeselect-items-wrapper) {
  display: flex;
}

:global(.p-treeselect.p-component.p-inputwrapper).inputValidationError:not(
    .p-inputwrapper-focus
  ) {
  border: 1px solid var(--Error-400);
}

.validationError {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 20px;
  color: var(--Error-600);
  font-weight: normal;
}

.validationError svg {
  margin: 3px 2px 0 0;
}

.validationError svg path {
  stroke: var(--Error-600);
}

:global(.p-tree-toggler) {
  margin-right: 0;
}

@media screen and (min-width: 576px) {
  :global(body .p-treeselect-panel) {
    width: initial;
  }
  :global(body .p-treenode-label) {
    max-width: 250px;
  }
}
