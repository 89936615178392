.list {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
}

.logRowWrapper {
  width: 100%;
  column-count: 3;
  gap: 16px;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.listItem,
.centralData {
  display: flex;
  flex-direction: row;
}

.logDot {
  padding-right: 16px;
}

.listItem {
  justify-content: space-between;
}

.extraData {
  min-width: 150px;
  font-size: 14px;
  color: var(--Grey-600);
}

.time {
  color: var(--Grey-800);
}

.rightData {
  color: var(--Grey-800);
  font-weight: 600;
  padding: 0;
}

.itemText {
  color: var(--Grey-800);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  width: 525px;
}

.alarmDetailsVDots {
  position: relative;
  width: 0;
  top: -18px;
  left: -1px;
}
