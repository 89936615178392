:global(.customTooltip) {
  max-width: 636px;
  background: var(--White);
  padding: 4px 8px;
  border: 1px solid var(--Grey-200);
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04),
    0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
}

:global(.customTooltipText) {
  display: inline;
  font-family: Poppins-Regular, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  background: var(--White);
  line-break: anywhere;
  color: var(--Grey-800);
  box-shadow: none;
  padding: 0;
}
:global(.p-tooltip-arrow) {
  display: none;
}

:global(.dark .p-tooltip-text) {
  background: var(--Grey-700);
  color: var(--White);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
}

:global(.customTooltip.dark) {
  background: var(--Grey-700);
}

:global(.passwordHint) {
  background: var(--Grey-100);
  padding: 8px;
  border-radius: 4px;
}

:global(.passwordHintText) {
  position: relative;
  background: var(--Grey-50);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid var(--Grey-100);
  color: black;
}

:global(.passwordHintText::after) {
  position: absolute;
  content: "";
  width: 14px;
  height: 14px;
  top: calc(50% - 7px);
  rotate: 45deg;
  right: -8px;
  background: var(--Grey-50);
  border-top: 1px solid var(--Grey-100);
  border-right: 1px solid var(--Grey-100);
}

:global(.left::after) {
  rotate: 225deg;
  left: -8px;
}
