#siteContent {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: 1;
}

:global(.mobile) #siteContent {
  width: 100%;
  position: relative;
}

#sidebarMenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
}

.hasSecondary #sidebarMenu {
  width: 260px;
}

:global(.mobile) #sidebarMenu {
  position: absolute;
  top: 0;
  left: -240px;
  transition: all 300ms ease-in-out;
  z-index: 200;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.1);
  border-right: 1px solid var(--Grey-150);
}

:global(.mobile) .hasSecondary #sidebarMenu {
  left: -260px;
}

:global(.mobile) #siteContent.showNavigation #sidebarMenu {
  -webkit-transform: translate3d(240px, 0, 0);
  transform: translate3d(240px, 0, 0);
}

:global(.mobile) #siteContent.hasSecondary.showNavigation #sidebarMenu {
  -webkit-transform: translate3d(260px, 0, 0);
  transform: translate3d(260px, 0, 0);
}

:global(.mobile) #siteContent.showNavigation .overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  display: flex;
  z-index: 900;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: auto;
}

:global(.mobile) #siteContent #sidebarMenu {
  z-index: 901;
}

.main {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  width: 100%;
}

.closeMenuButton {
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}
