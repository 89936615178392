.container {
  flex: 0;
  width: 100%;
}

.plate {
  align-self: stretch;
  margin: 32px;
}

.getStarted {
  margin-top: 16px;
  margin-bottom: 16px;
}

.rowGutters *:not(:last-child) {
  margin-right: 8px;
}
