/* CSS helper classes */

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.ml-auto {
  margin-left: auto !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.m-auto {
  margin: auto !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.p-0 {
  padding: 0 !important;
}

.line-height-0 {
  line-height: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.fw-600 {
  font-weight: 600 !important;
}

.flex0 {
  flex: 0 !important;
}

.align-items-center {
  align-items: center !important;
}

.grey-600 {
  color: var(--Grey-600) !important;
}
