.integrationWrapper {
  width: 100%;
  max-width: 784px;
}

.integrationDescription {
  max-width: 550px;
  color: var(--Grey-600);
  text-wrap: wrap;
}

.deleteIntegrationModal {
  width: 576px;
}

.deleteIntegrationModalDescription {
  max-width: 512px;
}
