.tagSelector {
  display: flex;
}

.tagOption {
  background: var(--White);
  border: 1px solid var(--Grey-200);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  color: var(--Grey-900);
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tagOption:not(:last-child) {
  margin-right: 8px;
}

.tagOptionPrimary {
  background: var(--Primary-700);
  color: var(--White);
  border-color: var(--Primary-700);
}

.tagOptionSuccess {
  background: var(--Success-50);
  border-color: var(--Success-100);
}

.tagContextMenuButton {
  margin-left: 4px;
  box-shadow: none;
}

.tagContextMenuContainer {
  padding: 8px 8px 0 8px;
}

.tagContextMenuContainer .tagOption {
  margin-bottom: 8px;
}

.inputValidationError:not(.p-inputwrapper-focus) {
  border: 1px solid var(--Error-400);
  border-radius: 4px;
}

.dayOption {
  font-family: Poppins-SemiBold;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 40px;
  height: 40px;
}

.dayOption:not(:last-child) {
  margin-right: 8px;
}

.dayOptionSelected {
  background: var(--Primary-700);
  color: var(--White);
  border-color: var(--Primary-700);
}
