.navigation {
  display: block;
  padding: 20px;
  max-width: 500px;
  margin: auto;
}

.navigationItem {
  font-family: Poppins-SemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: var(--Grey-500);
  letter-spacing: 0.2px;
}

.languageDropdown > span {
  font-family: Poppins-SemiBold, sans-serif;
}

@media screen and (min-width: 576px) {
  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .navigation{
    margin: 0;
    justify-content: flex-start;
    padding: 0 80px;
    max-width: none;
    max-height: 48px;
    height: 100%;
  }

  .navigationItem:hover {
    color: var(--Grey-500);
    cursor: pointer;
    opacity: 0.8;
  }
}
