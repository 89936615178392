.objectGeneralWrapper {
  width: 100%;
  & h1 {
    font-size: 20px;
    margin-bottom: 4px;
    line-height: 28px;
  }
  & p {
    letter-spacing: 0.2px;
  }
}

.ogjectTabsContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  overflow: scroll;
  margin-top: 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  & button {
    white-space: nowrap;
  }
}

.ObjectBasicContainer {
  margin-top: 20px;

  & h1 {
    font-size: 20px;
    margin-bottom: 4px;
    line-height: 28px;
  }
}

.personalInfoWrapper {
  margin-top: 20px;
}

.morePersonalInfoWrapper {
  margin-top: 32px;
}

.namePhotoRow {
  flex-direction: row-reverse;
  margin-bottom: 20px;
}

.tmpAlarmHandlingBtn {
  width: 100%;
  border-color: var(--Primary-700);
}

@media screen and (min-width: 576px) {
  .objectGeneralWrapper {
    max-width: 784px;
    & h1 {
      font-size: 28px;
      margin-bottom: 8px;
      line-height: 32px;
    }
  }

  .ObjectBasicContainer {
    margin-top: 32px;
  }

  .namePhotoRow {
    flex-direction: row;
    margin-bottom: 0;
  }

  .personalInfoContainer {
    display: flex;
    flex-direction: column;
  }

  .personalInfoWrapper {
    display: contents;
  }

  .morePersonalInfoWrapper {
    order: 3;
    margin-top: 0;
  }
  .tmpAlarmHandlingBtn {
    width: max-content;
  }
}
