.planningWrapper {
  display: flex;
  height: 100%;
  border: 1px solid var(--Grey-200);
  border-radius: 4px;
}

.planningList {
  width: 100%;
  background: var(--Grey-50);
  padding: 32px 24px 0;
}

.planningCalendar :global(.p-datepicker-inline .p-datepicker-group-container) {
  display: flex;
  flex-direction: column;
}

.planningCalendar :global(.p-datepicker-group:first-of-type) {
  margin-bottom: 16px;
  border-left: none;
}

.planningCalendar
  :global(.p-datepicker-group:nth-child(2) .p-datepicker-header) {
  border-bottom: none;
  justify-content: flex-end;
}
.planningCalendar
  :global(.p-datepicker-group:first-of-type .p-datepicker-header) {
  border-bottom: none;
  justify-content: flex-start;
}

.planningCalendar
  :global(.p-datepicker.p-component table.p-datepicker-calendar) {
  margin: 0;
}

/* .planningCalendar :global(.p-datepicker td > span) {
align-items: flex-start;
} */

.planningCalendar .withActions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.planningCalendar .withActions:after {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--Primary-700);
}
