/* 
**************************** Local component styles ****************************
*/
.primeDropdown {
  & span {
    padding: 0 8px 0 0;
    font-size: 12px;
    font-weight: 600;
    color: var(--Grey-500);
  }

  & :global(.p-dropdown-trigger) {
    color: var(--Grey-500);
    width: auto;
    & > svg {
      width: 12px;
    }
  }
}

.noBorder {
  border: none;
}

/* 
**************************** Global dropdown styles ****************************
*/

:global(.p-dropdown-panel .p-dropdown-items .p-dropdown-item) {
  padding: 0.5rem;
}

:global(.p-dropdown:not(.p-disabled).p-focus) {
  box-shadow: 0 0 4px 2px var(--Primary-700);
}
