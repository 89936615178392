.retentionWrapper {
  max-width: 784px;
  width: 100%;
}

.retentionWrapper p {
  color: var(--Grey-600);
}

.dropdownItem {
  max-width: 376px;
}
